package com.zegreatrob.coupling.client.components

import kotlin.Double
import kotlin.sequences.Sequence
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun <S> FrameRunnerProps<S>.component1(): Sequence<Frame<S>> = sequence

public operator fun <S> FrameRunnerProps<S>.component2(): Double = speed

public operator fun <S> FrameRunnerProps<S>.component3(): (S) -> ReactNode = child

public operator fun <S> FrameRunnerProps<S>.component4(): Key? = key

public fun <S> ElementType<FrameRunnerProps<S>>.create(
  sequence: Sequence<Frame<S>>,
  speed: Double,
  child: (S) -> ReactNode,
  key: Key? = null,
): ReactNode {
   return create {
         this.sequence = sequence
  this.speed = speed
  this.child = child
  key?.let { this.key = it }
         }
}
