package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.model.player.Player
import kotlin.collections.Iterable
import react.ChildrenBuilder
import react.FC
import react.Key
import react.ReactNode
import web.cssom.Angle

public fun ChildrenBuilder.TiltedPlayerList(
  playerList: Iterable<Player>,
  element: (Angle, Player) -> ReactNode,
  key: Key? = null,
) {
    val component = (TiltedPlayerList.unsafeCast<FC<TiltedPlayerListProps>>())
    component {
         this.playerList = playerList
  this.element = element
  key?.let { this.key = it }
         }
}
