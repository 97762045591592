package com.zegreatrob.coupling.client.components.player

import com.zegreatrob.coupling.model.player.Player
import kotlin.Int
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.PlayerCardHeader(
  player: Player,
  size: Int,
  key: Key? = null,
) {
    val component = (PlayerCardHeader.unsafeCast<FC<PlayerCardHeaderProps>>())
    component {
         this.player = player
  this.size = size
  key?.let { this.key = it }
         }
}
