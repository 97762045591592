package com.zegreatrob.coupling.client.components.`external`.nivo

import js.objects.jso
import kotlin.Any
import kotlin.Array

public operator fun NivoHeatMapData.component1(): Any = id

public operator fun NivoHeatMapData.component2(): Array<NivoPoint> = data

public fun NivoHeatMapData(id: Any, `data`: Array<NivoPoint>): NivoHeatMapData {
    return jso<NivoHeatMapData> {
         this.id = id
  this.data = data
         }
}
