package com.zegreatrob.coupling.client.components.`external`.auth0.react

import js.objects.jso

public operator fun RedirectLoginOptions.component1(): TAppState = appState

public fun RedirectLoginOptions(appState: TAppState): RedirectLoginOptions {
    return jso<RedirectLoginOptions> {
         this.appState = appState
         }
}
