package com.zegreatrob.coupling.client.components.pin

import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.pin.Pin
import kotlin.Unit
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create
import react.dom.events.ChangeEvent

public operator fun PinConfigContentProps.component1(): PartyDetails = party

public operator fun PinConfigContentProps.component2(): Boost? = boost

public operator fun PinConfigContentProps.component3(): Pin = pin

public operator fun PinConfigContentProps.component4(): List<Pin> = pinList

public operator fun PinConfigContentProps.component5(): (ChangeEvent<*>) -> Unit = onChange

public operator fun PinConfigContentProps.component6(): () -> Unit = onSubmit

public operator fun PinConfigContentProps.component7(): (() -> Unit)? = onRemove

public operator fun PinConfigContentProps.component8(): Key? = key

public fun ElementType<PinConfigContentProps>.create(
  party: PartyDetails,
  boost: Boost? = null,
  pin: Pin,
  pinList: List<Pin>,
  onChange: (ChangeEvent<*>) -> Unit,
  onSubmit: () -> Unit,
  onRemove: (() -> Unit)? = null,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  boost?.let { this.boost = it }
  this.pin = pin
  this.pinList = pinList
  this.onChange = onChange
  this.onSubmit = onSubmit
  onRemove?.let { this.onRemove = it }
  key?.let { this.key = it }
         }
}
