package com.zegreatrob.coupling.client.components.stats

import com.zegreatrob.coupling.model.pairassignmentdocument.CouplingPair
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.PairSelector(
  pairs: List<CouplingPair>,
  selectedPairs: List<CouplingPair>,
  onSelectionChange: (List<CouplingPair>) -> Unit,
  key: Key? = null,
) {
    val component = (PairSelector.unsafeCast<FC<PairSelectorProps>>())
    component {
         this.pairs = pairs
  this.selectedPairs = selectedPairs
  this.onSelectionChange = onSelectionChange
  key?.let { this.key = it }
         }
}
