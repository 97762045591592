package com.zegreatrob.coupling.client.components.stats

import kotlin.Int
import kotlin.time.Duration
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun TeamStatisticsProps.component1(): Int = spinsUntilFullRotation

public operator fun TeamStatisticsProps.component2(): Int = activePlayerCount

public operator fun TeamStatisticsProps.component3(): Duration? = medianSpinDuration

public operator fun TeamStatisticsProps.component4(): Key? = key

public fun ElementType<TeamStatisticsProps>.create(
  spinsUntilFullRotation: Int,
  activePlayerCount: Int,
  medianSpinDuration: Duration? = null,
  key: Key? = null,
): ReactNode {
   return create {
         this.spinsUntilFullRotation = spinsUntilFullRotation
  this.activePlayerCount = activePlayerCount
  medianSpinDuration?.let { this.medianSpinDuration = it }
  key?.let { this.key = it }
         }
}
