package com.zegreatrob.coupling.client.components.pairassignments

import com.zegreatrob.coupling.model.pairassignmentdocument.PinnedCouplingPair
import com.zegreatrob.coupling.model.pairassignmentdocument.PinnedPlayer
import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.AssignedPair(
  party: PartyDetails,
  pair: PinnedCouplingPair,
  canDrag: Boolean,
  swapPlayersFunc: ((PinnedPlayer, String) -> Unit)? = null,
  pinDropFunc: PinMoveCallback? = null,
  key: Key? = null,
) {
    val component = (AssignedPair.unsafeCast<FC<AssignedPairProps>>())
    component {
         this.party = party
  this.pair = pair
  this.canDrag = canDrag
  swapPlayersFunc?.let { this.swapPlayersFunc = it }
  pinDropFunc?.let { this.pinDropFunc = it }
  key?.let { this.key = it }
         }
}
