package com.zegreatrob.coupling.client.incubating

import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.String
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun IncubatingContentProps.component1(): String = discordClientId

public operator fun IncubatingContentProps.component2(): String = addToSlackUrl

public operator fun IncubatingContentProps.component3(): List<PartyDetails> = partyList

public operator fun IncubatingContentProps.component4(): Key? = key

public fun ElementType<IncubatingContentProps>.create(
  discordClientId: String,
  addToSlackUrl: String,
  partyList: List<PartyDetails>,
  key: Key? = null,
): ReactNode {
   return create {
         this.discordClientId = discordClientId
  this.addToSlackUrl = addToSlackUrl
  this.partyList = partyList
  key?.let { this.key = it }
         }
}
