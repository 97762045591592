package com.zegreatrob.coupling.client.components.player

import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.dom.events.ChangeEvent

public fun ChildrenBuilder.PlayerConfigContent(
  party: PartyDetails,
  boost: Boost? = null,
  player: Player,
  players: List<Player>,
  onChange: (ChangeEvent<*>) -> Unit,
  onSubmit: () -> Unit,
  onRemove: () -> Unit,
  onPlayerChange: (Player) -> Unit,
  key: Key? = null,
) {
    val component = (PlayerConfigContent.unsafeCast<FC<PlayerConfigContentProps>>())
    component {
         this.party = party
  boost?.let { this.boost = it }
  this.player = player
  this.players = players
  this.onChange = onChange
  this.onSubmit = onSubmit
  this.onRemove = onRemove
  this.onPlayerChange = onPlayerChange
  key?.let { this.key = it }
         }
}
