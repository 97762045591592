package com.zegreatrob.coupling.client.components.party

import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.Boolean
import kotlin.Unit
import react.ElementType
import react.Key
import react.ReactNode
import react.create
import react.dom.events.ChangeEvent

public operator fun PartyConfigContentProps.component1(): PartyDetails = party

public operator fun PartyConfigContentProps.component2(): Boost? = boost

public operator fun PartyConfigContentProps.component3(): Boolean? = isNew

public operator fun PartyConfigContentProps.component4(): (ChangeEvent<*>) -> Unit = onChange

public operator fun PartyConfigContentProps.component5(): () -> Unit = onSave

public operator fun PartyConfigContentProps.component6(): (() -> Unit)? = onDelete

public operator fun PartyConfigContentProps.component7(): Key? = key

public fun ElementType<PartyConfigContentProps>.create(
  party: PartyDetails,
  boost: Boost? = null,
  isNew: Boolean? = null,
  onChange: (ChangeEvent<*>) -> Unit,
  onSave: () -> Unit,
  onDelete: (() -> Unit)? = null,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  boost?.let { this.boost = it }
  isNew?.let { this.isNew = it }
  this.onChange = onChange
  this.onSave = onSave
  onDelete?.let { this.onDelete = it }
  key?.let { this.key = it }
         }
}
