package com.zegreatrob.coupling.client.components.pairassignments.spin

import com.zegreatrob.coupling.model.pairassignmentdocument.PairAssignmentDocument
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.Boolean
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Fragment
import react.Key

public fun ChildrenBuilder.PairAssignmentsAnimator(
  party: PartyDetails,
  players: List<Player>,
  pairAssignments: PairAssignmentDocument,
  enabled: Boolean,
  key: Key? = null,
  children: ChildrenBuilder.() -> Unit = {},
) {
    val component = (PairAssignmentsAnimator.unsafeCast<FC<PairAssignmentsAnimatorProps>>())
    component {
         this.party = party
  this.players = players
  this.pairAssignments = pairAssignments
  this.enabled = enabled
  key?.let { this.key = it }
  Fragment { children() }

         }
}
