package com.zegreatrob.coupling.client.components.spin

import com.zegreatrob.coupling.action.SpinCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.pairassignmentdocument.PairAssignmentDocument
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.pin.Pin
import com.zegreatrob.coupling.model.player.Player
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PrepareSpinProps.component1(): PartyDetails = party

public operator fun PrepareSpinProps.component2(): List<Player> = players

public operator fun PrepareSpinProps.component3(): PairAssignmentDocument? = currentPairsDoc

public operator fun PrepareSpinProps.component4(): List<Pin> = pins

public operator fun PrepareSpinProps.component5(): DispatchFunc<SpinCommand.Dispatcher> = dispatchFunc

public operator fun PrepareSpinProps.component6(): Key? = key

public fun ElementType<PrepareSpinProps>.create(
  party: PartyDetails,
  players: List<Player>,
  currentPairsDoc: PairAssignmentDocument? = null,
  pins: List<Pin>,
  dispatchFunc: DispatchFunc<SpinCommand.Dispatcher>,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  this.players = players
  currentPairsDoc?.let { this.currentPairsDoc = it }
  this.pins = pins
  this.dispatchFunc = dispatchFunc
  key?.let { this.key = it }
         }
}
