package com.zegreatrob.coupling.client.routing

import com.zegreatrob.react.dataloader.DataLoadState
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Fragment
import react.Key

public fun ChildrenBuilder.animationFrame(
  state: DataLoadState<*>,
  key: Key? = null,
  children: ChildrenBuilder.() -> Unit = {},
) {
    val component = (animationFrame.unsafeCast<FC<AnimationFrameProps>>())
    component {
         this.state = state
  key?.let { this.key = it }
  Fragment { children() }

         }
}
