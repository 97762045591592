package com.zegreatrob.coupling.client.pin

import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.pin.Pin
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PinListProps.component1(): PartyDetails = party

public operator fun PinListProps.component2(): List<Pin> = pins

public operator fun PinListProps.component3(): Key? = key

public fun ElementType<PinListProps>.create(
  party: PartyDetails,
  pins: List<Pin>,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  this.pins = pins
  key?.let { this.key = it }
         }
}
