package com.zegreatrob.coupling.client.components.player

import com.zegreatrob.coupling.model.player.Player
import kotlin.Boolean
import kotlin.Int
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Key
import web.cssom.Angle
import web.cssom.ClassName

public fun ChildrenBuilder.PlayerCard(
  player: Player,
  className: ClassName? = null,
  onClick: (() -> Unit)? = null,
  size: Int? = null,
  deselected: Boolean? = null,
  tilt: Angle? = null,
  key: Key? = null,
) {
    val component = (PlayerCard.unsafeCast<FC<PlayerCardProps>>())
    component {
         this.player = player
  className?.let { this.className = it }
  onClick?.let { this.onClick = it }
  size?.let { this.size = it }
  deselected?.let { this.deselected = it }
  tilt?.let { this.tilt = it }
  key?.let { this.key = it }
         }
}
