package com.zegreatrob.coupling.client.components.`external`.auth0.react

import js.objects.jso
import kotlin.String

public operator fun Auth0AuthorizationParams.component1(): String = redirectUri

public operator fun Auth0AuthorizationParams.component2(): String = audience

public operator fun Auth0AuthorizationParams.component3(): String = scope

public fun Auth0AuthorizationParams(
  redirectUri: String,
  audience: String,
  scope: String,
): Auth0AuthorizationParams {
    return jso<Auth0AuthorizationParams> {
         this.redirectUri = redirectUri
  this.audience = audience
  this.scope = scope
         }
}
