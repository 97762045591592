package com.zegreatrob.coupling.client.components.party

import kotlin.Double
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun CouplingLogoProps.component1(): Double = width

public operator fun CouplingLogoProps.component2(): Double = height

public operator fun CouplingLogoProps.component3(): Key? = key

public fun ElementType<CouplingLogoProps>.create(
  width: Double,
  height: Double,
  key: Key? = null,
): ReactNode {
   return create {
         this.width = width
  this.height = height
  key?.let { this.key = it }
         }
}
