package com.zegreatrob.coupling.client.components.user

import com.zegreatrob.coupling.action.ApplyBoostCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.user.SubscriptionDetails
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.BoostConfiguration(
  subscription: SubscriptionDetails? = null,
  boost: Boost? = null,
  parties: List<PartyDetails>,
  dispatchFunc: DispatchFunc<ApplyBoostCommand.Dispatcher>,
  reload: () -> Unit,
  key: Key? = null,
) {
    val component = (BoostConfiguration.unsafeCast<FC<BoostConfigurationProps>>())
    component {
         subscription?.let { this.subscription = it }
  boost?.let { this.boost = it }
  this.parties = parties
  this.dispatchFunc = dispatchFunc
  this.reload = reload
  key?.let { this.key = it }
         }
}
