package com.zegreatrob.coupling.client.components.stats

import com.zegreatrob.coupling.action.stats.PairReport
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.PairReportTable(pairReports: List<PairReport>, key: Key? = null) {
    val component = (PairReportTable.unsafeCast<FC<PairReportTableProps>>())
    component {
         this.pairReports = pairReports
  key?.let { this.key = it }
         }
}
