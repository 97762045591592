package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.action.player.SavePlayerCommand
import com.zegreatrob.coupling.model.party.PartyId
import com.zegreatrob.coupling.model.player.Player
import kotlin.Unit
import kotlin.collections.List
import popper.core.ReferenceElement
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun ContributionPopUpMenuProps.component1(): PartyId = partyId

public operator fun ContributionPopUpMenuProps.component2(): List<Player> = players

public operator fun ContributionPopUpMenuProps.component3(): DispatchFunc<SavePlayerCommand.Dispatcher> = dispatchFunc

public operator fun ContributionPopUpMenuProps.component4(): ((ReferenceElement, Player) -> Unit) -> ReactNode = child

public operator fun ContributionPopUpMenuProps.component5(): Key? = key

public fun ElementType<ContributionPopUpMenuProps>.create(
  partyId: PartyId,
  players: List<Player>,
  dispatchFunc: DispatchFunc<SavePlayerCommand.Dispatcher>,
  child: ((ReferenceElement, Player) -> Unit) -> ReactNode,
  key: Key? = null,
): ReactNode {
   return create {
         this.partyId = partyId
  this.players = players
  this.dispatchFunc = dispatchFunc
  this.child = child
  key?.let { this.key = it }
         }
}
