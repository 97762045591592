package com.zegreatrob.coupling.client.pairassignments.list

import com.zegreatrob.coupling.action.pairassignmentdocument.DeletePairAssignmentsCommand
import com.zegreatrob.coupling.client.components.Controls
import com.zegreatrob.coupling.client.components.`external`.w3c.WindowFunctions
import com.zegreatrob.coupling.model.pairassignmentdocument.PairAssignmentDocument
import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun HistoryProps.component1(): PartyDetails = party

public operator fun HistoryProps.component2(): List<PairAssignmentDocument> = history

public operator fun HistoryProps.component3(): Controls<DeletePairAssignmentsCommand.Dispatcher> = controls

public operator fun HistoryProps.component4(): WindowFunctions? = windowFunctions

public operator fun HistoryProps.component5(): Key? = key

public fun ElementType<HistoryProps>.create(
  party: PartyDetails,
  history: List<PairAssignmentDocument>,
  controls: Controls<DeletePairAssignmentsCommand.Dispatcher>,
  windowFunctions: WindowFunctions? = null,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  this.history = history
  this.controls = controls
  windowFunctions?.let { this.windowFunctions = it }
  key?.let { this.key = it }
         }
}
