package com.zegreatrob.coupling.client.components.contribution

import kotlin.String
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.ContributionLinkButton(link: String, key: Key? = null) {
    val component = (ContributionLinkButton.unsafeCast<FC<ContributionLinkButtonProps>>())
    component {
         this.link = link
  key?.let { this.key = it }
         }
}
