package com.zegreatrob.coupling.client.components.pairassignments

import com.zegreatrob.coupling.model.pairassignmentdocument.PinnedCouplingPair
import com.zegreatrob.coupling.model.pairassignmentdocument.PinnedPlayer
import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun AssignedPairProps.component1(): PartyDetails = party

public operator fun AssignedPairProps.component2(): PinnedCouplingPair = pair

public operator fun AssignedPairProps.component3(): Boolean = canDrag

public operator fun AssignedPairProps.component4(): ((PinnedPlayer, String) -> Unit)? = swapPlayersFunc

public operator fun AssignedPairProps.component5(): PinMoveCallback? = pinDropFunc

public operator fun AssignedPairProps.component6(): Key? = key

public fun ElementType<AssignedPairProps>.create(
  party: PartyDetails,
  pair: PinnedCouplingPair,
  canDrag: Boolean,
  swapPlayersFunc: ((PinnedPlayer, String) -> Unit)? = null,
  pinDropFunc: PinMoveCallback? = null,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  this.pair = pair
  this.canDrag = canDrag
  swapPlayersFunc?.let { this.swapPlayersFunc = it }
  pinDropFunc?.let { this.pinDropFunc = it }
  key?.let { this.key = it }
         }
}
