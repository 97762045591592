package com.zegreatrob.coupling.client.components.party

import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.Int
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PartyCardHeaderProps.component1(): PartyDetails = party

public operator fun PartyCardHeaderProps.component2(): Int = size

public operator fun PartyCardHeaderProps.component3(): Key? = key

public fun ElementType<PartyCardHeaderProps>.create(
  party: PartyDetails,
  size: Int,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  this.size = size
  key?.let { this.key = it }
         }
}
