package com.zegreatrob.coupling.client.components.contribution

import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.Unit
import react.ChildrenBuilder
import react.ElementType
import react.Fragment
import react.Key
import react.ReactNode
import react.create

public operator fun ContributionContentFrameProps.component1(): PartyDetails = party

public operator fun ContributionContentFrameProps.component2(): ReactNode? = children

public operator fun ContributionContentFrameProps.component3(): Key? = key

public fun ElementType<ContributionContentFrameProps>.create(
  party: PartyDetails,
  key: Key? = null,
  children: ChildrenBuilder.() -> Unit = {},
): ReactNode {
   return create {
         this.party = party
  key?.let { this.key = it }
  Fragment { children() }

         }
}
