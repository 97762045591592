package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.model.Message
import com.zegreatrob.coupling.model.party.PartyId
import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Key
import react.ReactNode

public fun ChildrenBuilder.CouplingWebsocket(
  partyId: PartyId,
  onMessage: (Message) -> Unit,
  buildChild: (((Message) -> Unit)?) -> ReactNode,
  token: String,
  useSsl: Boolean? = null,
  key: Key? = null,
) {
    val component = (CouplingWebsocket.unsafeCast<FC<CouplingWebsocketProps>>())
    component {
         this.partyId = partyId
  this.onMessage = onMessage
  this.buildChild = buildChild
  this.token = token
  useSsl?.let { this.useSsl = it }
  key?.let { this.key = it }
         }
}
