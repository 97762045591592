package com.zegreatrob.coupling.client.routing

import com.zegreatrob.coupling.client.ClientDispatcher
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.react.dataloader.ReloadFunc
import com.zegreatrob.testmints.action.async.SuspendAction
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun <R> CouplingQueryProps<R>.component1(): SuspendAction<ClientDispatcher, R?> = query

public operator fun <R> CouplingQueryProps<R>.component2(): (
  ReloadFunc,
  DispatchFunc<ClientDispatcher>,
  R,
) -> ReactNode? = toNode

public operator fun <R> CouplingQueryProps<R>.component3(): Commander = commander

public operator fun <R> CouplingQueryProps<R>.component4(): Key? = key

public fun <R> ElementType<CouplingQueryProps<R>>.create(
  query: SuspendAction<ClientDispatcher, R?>,
  toNode: (
    ReloadFunc,
    DispatchFunc<ClientDispatcher>,
    R,
  ) -> ReactNode?,
  commander: Commander,
  key: Key? = null,
): ReactNode {
   return create {
         this.query = query
  this.toNode = toNode
  this.commander = commander
  key?.let { this.key = it }
         }
}
