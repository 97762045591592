package com.zegreatrob.coupling.client.components.stats

import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import react.ReactNode
import react.dom.html.SelectHTMLAttributes
import web.cssom.BackgroundColor
import web.html.HTMLSelectElement

public fun <E> ChildrenBuilder.EnumSelector(
  entries: List<E>,
  default: E,
  onChange: (E) -> Unit,
  valueOf: (String) -> E,
  enumName: (E) -> String,
  backgroundColor: BackgroundColor? = null,
  label: ReactNode? = null,
  selectProps: SelectHTMLAttributes<HTMLSelectElement>? = null,
  key: Key? = null,
) {
    val component = (EnumSelector.unsafeCast<FC<EnumSelectorProps<E>>>())
    component {
         this.entries = entries
  this.default = default
  this.onChange = onChange
  this.valueOf = valueOf
  this.enumName = enumName
  backgroundColor?.let { this.backgroundColor = it }
  label?.let { this.label = it }
  selectProps?.let { this.selectProps = it }
  key?.let { this.key = it }
         }
}
