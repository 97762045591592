package com.zegreatrob.coupling.client.components.contribution

import kotlin.String
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun ContributionLinkButtonProps.component1(): String = link

public operator fun ContributionLinkButtonProps.component2(): Key? = key

public fun ElementType<ContributionLinkButtonProps>.create(link: String, key: Key? = null): ReactNode {
   return create {
         this.link = link
  key?.let { this.key = it }
         }
}
