package com.zegreatrob.coupling.client.components

import kotlin.String
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Fragment
import react.Key

public fun ChildrenBuilder.CouplingDropDownLink(
  to: String,
  key: Key? = null,
  children: ChildrenBuilder.() -> Unit = {},
) {
    val component = (CouplingDropDownLink.unsafeCast<FC<CouplingDropDownLinkProps>>())
    component {
         this.to = to
  key?.let { this.key = it }
  Fragment { children() }

         }
}
