package com.zegreatrob.coupling.client.components.stats

import com.zegreatrob.coupling.model.player.Player
import kotlin.Double
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PlayerHeatmapProps.component1(): List<Player> = players

public operator fun PlayerHeatmapProps.component2(): List<List<Double?>> = heatmapData

public operator fun PlayerHeatmapProps.component3(): Key? = key

public fun ElementType<PlayerHeatmapProps>.create(
  players: List<Player>,
  heatmapData: List<List<Double?>>,
  key: Key? = null,
): ReactNode {
   return create {
         this.players = players
  this.heatmapData = heatmapData
  key?.let { this.key = it }
         }
}
