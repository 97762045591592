package com.zegreatrob.coupling.client.components.pairassignments

import csstype.PropertiesBuilder
import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import kotlin.js.Json
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun DraggableThingProps.component1(): String = itemType

public operator fun DraggableThingProps.component2(): String = itemId

public operator fun DraggableThingProps.component3(): (String) -> Unit = dropCallback

public operator fun DraggableThingProps.component4(): (String, Json?) -> Unit = endCallback

public operator fun DraggableThingProps.component5(): (PropertiesBuilder) -> Unit = css

public operator fun DraggableThingProps.component6(): (Boolean) -> ReactNode = handler

public operator fun DraggableThingProps.component7(): Key? = key

public fun ElementType<DraggableThingProps>.create(
  itemType: String,
  itemId: String,
  dropCallback: (String) -> Unit,
  endCallback: (String, Json?) -> Unit,
  css: (PropertiesBuilder) -> Unit,
  handler: (Boolean) -> ReactNode,
  key: Key? = null,
): ReactNode {
   return create {
         this.itemType = itemType
  this.itemId = itemId
  this.dropCallback = dropCallback
  this.endCallback = endCallback
  this.css = css
  this.handler = handler
  key?.let { this.key = it }
         }
}
