package com.zegreatrob.coupling.client.components.`external`.nivo

import js.objects.jso
import kotlin.Array
import kotlin.String

public operator fun NivoLineData.component1(): String = id

public operator fun NivoLineData.component2(): Array<NivoPoint> = data

public operator fun NivoLineData.component3(): String? = color

public fun NivoLineData(
  id: String,
  `data`: Array<NivoPoint>,
  color: String? = null,
): NivoLineData {
    return jso<NivoLineData> {
         this.id = id
  this.data = data
  color?.let { this.color = it }
         }
}
