package com.zegreatrob.coupling.client.components.stats

import kotlin.String
import kotlin.Unit
import kotlin.collections.Set
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.ContributionLabelFilter(
  allLabels: Set<String>,
  selected: String? = null,
  setSelected: (String?) -> Unit,
  key: Key? = null,
) {
    val component = (ContributionLabelFilter.unsafeCast<FC<ContributionLabelFilterProps>>())
    component {
         this.allLabels = allLabels
  selected?.let { this.selected = it }
  this.setSelected = setSelected
  key?.let { this.key = it }
         }
}
