package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.model.party.PartyDetails
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PartyNavigationProps.component1(): PartyDetails = party

public operator fun PartyNavigationProps.component2(): Key? = key

public fun ElementType<PartyNavigationProps>.create(party: PartyDetails, key: Key? = null): ReactNode {
   return create {
         this.party = party
  key?.let { this.key = it }
         }
}
