package com.zegreatrob.coupling.client.components.party

import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.Int
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PartyCardProps.component1(): PartyDetails = party

public operator fun PartyCardProps.component2(): Int? = size

public operator fun PartyCardProps.component3(): Boost? = boost

public operator fun PartyCardProps.component4(): Key? = key

public fun ElementType<PartyCardProps>.create(
  party: PartyDetails,
  size: Int? = null,
  boost: Boost? = null,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  size?.let { this.size = it }
  boost?.let { this.boost = it }
  key?.let { this.key = it }
         }
}
