package com.zegreatrob.coupling.client.components.stats

import com.zegreatrob.coupling.model.player.Player
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.TopRowPlayer(player: Player, key: Key? = null) {
    val component = (TopRowPlayer.unsafeCast<FC<TopRowPlayerProps>>())
    component {
         this.player = player
  key?.let { this.key = it }
         }
}
