package com.zegreatrob.coupling.client.components.stats

import kotlin.String
import kotlin.Unit
import kotlin.collections.Set
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun ContributionLabelFilterProps.component1(): Set<String> = allLabels

public operator fun ContributionLabelFilterProps.component2(): String? = selected

public operator fun ContributionLabelFilterProps.component3(): (String?) -> Unit = setSelected

public operator fun ContributionLabelFilterProps.component4(): Key? = key

public fun ElementType<ContributionLabelFilterProps>.create(
  allLabels: Set<String>,
  selected: String? = null,
  setSelected: (String?) -> Unit,
  key: Key? = null,
): ReactNode {
   return create {
         this.allLabels = allLabels
  selected?.let { this.selected = it }
  this.setSelected = setSelected
  key?.let { this.key = it }
         }
}
