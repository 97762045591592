package com.zegreatrob.coupling.client.contribution

import com.zegreatrob.coupling.client.routing.Commander
import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.Int
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun ContributionVisualizationProps.component1(): Commander = commander

public operator fun ContributionVisualizationProps.component2(): PartyDetails = party

public operator fun ContributionVisualizationProps.component3(): Int = spinsUntilFullRotation

public operator fun ContributionVisualizationProps.component4(): Key? = key

public fun ElementType<ContributionVisualizationProps>.create(
  commander: Commander,
  party: PartyDetails,
  spinsUntilFullRotation: Int,
  key: Key? = null,
): ReactNode {
   return create {
         this.commander = commander
  this.party = party
  this.spinsUntilFullRotation = spinsUntilFullRotation
  key?.let { this.key = it }
         }
}
