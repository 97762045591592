package com.zegreatrob.coupling.client.components.pin

import com.zegreatrob.coupling.model.party.PartyId
import com.zegreatrob.coupling.model.pin.Pin
import kotlin.Boolean
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.PinCard(
  partyId: PartyId,
  pin: Pin,
  shouldLink: Boolean? = null,
  key: Key? = null,
) {
    val component = (PinCard.unsafeCast<FC<PinCardProps>>())
    component {
         this.partyId = partyId
  this.pin = pin
  shouldLink?.let { this.shouldLink = it }
  key?.let { this.key = it }
         }
}
