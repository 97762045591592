package com.zegreatrob.coupling.client.components.pin

import com.zegreatrob.coupling.model.pin.Pin
import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.PinButton(
  pin: Pin,
  scale: PinButtonScale? = null,
  className: String? = null,
  showTooltip: Boolean? = null,
  tooltipMessage: String? = null,
  onClick: (() -> Unit)? = null,
  key: Key? = null,
) {
    val component = (PinButton.unsafeCast<FC<PinButtonProps>>())
    component {
         this.pin = pin
  scale?.let { this.scale = it }
  className?.let { this.className = it }
  showTooltip?.let { this.showTooltip = it }
  tooltipMessage?.let { this.tooltipMessage = it }
  onClick?.let { this.onClick = it }
  key?.let { this.key = it }
         }
}
