package com.zegreatrob.coupling.client.components.spin

import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.pin.Pin
import com.zegreatrob.coupling.model.player.Player
import kotlin.Boolean
import kotlin.Pair
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.PrepareSpinContent(
  party: PartyDetails,
  playerSelections: List<Pair<Player, Boolean>>,
  pins: List<Pin>,
  pinSelections: List<String>,
  setPlayerSelections: (List<Pair<Player, Boolean>>) -> Unit,
  setPinSelections: (List<String>) -> Unit,
  onSpin: (() -> Unit)? = null,
  key: Key? = null,
) {
    val component = (PrepareSpinContent.unsafeCast<FC<PrepareSpinContentProps>>())
    component {
         this.party = party
  this.playerSelections = playerSelections
  this.pins = pins
  this.pinSelections = pinSelections
  this.setPlayerSelections = setPlayerSelections
  this.setPinSelections = setPinSelections
  onSpin?.let { this.onSpin = it }
  key?.let { this.key = it }
         }
}
