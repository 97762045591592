package com.zegreatrob.coupling.client.components.stats

import com.zegreatrob.coupling.model.player.Player
import kotlin.Double
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.PlayerHeatmap(
  players: List<Player>,
  heatmapData: List<List<Double?>>,
  key: Key? = null,
) {
    val component = (PlayerHeatmap.unsafeCast<FC<PlayerHeatmapProps>>())
    component {
         this.players = players
  this.heatmapData = heatmapData
  key?.let { this.key = it }
         }
}
