package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.model.player.Player
import kotlin.collections.Iterable
import react.ElementType
import react.Key
import react.ReactNode
import react.create
import web.cssom.Angle

public operator fun TiltedPlayerListProps.component1(): Iterable<Player> = playerList

public operator fun TiltedPlayerListProps.component2(): (Angle, Player) -> ReactNode = element

public operator fun TiltedPlayerListProps.component3(): Key? = key

public fun ElementType<TiltedPlayerListProps>.create(
  playerList: Iterable<Player>,
  element: (Angle, Player) -> ReactNode,
  key: Key? = null,
): ReactNode {
   return create {
         this.playerList = playerList
  this.element = element
  key?.let { this.key = it }
         }
}
