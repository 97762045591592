package com.zegreatrob.coupling.client.components.contribution

import com.zegreatrob.coupling.model.Contribution
import com.zegreatrob.coupling.model.player.Player
import kotlin.Unit
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create
import web.html.HTMLElement

public operator fun ContributionCardHeaderProps.component1(): Contribution = contribution

public operator fun ContributionCardHeaderProps.component2(): List<Player> = contributors

public operator fun ContributionCardHeaderProps.component3(): ((Player, HTMLElement) -> Unit)? = onPlayerClick

public operator fun ContributionCardHeaderProps.component4(): Key? = key

public fun ElementType<ContributionCardHeaderProps>.create(
  contribution: Contribution,
  contributors: List<Player>,
  onPlayerClick: ((Player, HTMLElement) -> Unit)? = null,
  key: Key? = null,
): ReactNode {
   return create {
         this.contribution = contribution
  this.contributors = contributors
  onPlayerClick?.let { this.onPlayerClick = it }
  key?.let { this.key = it }
         }
}
