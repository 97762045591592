package com.zegreatrob.coupling.client.components.pairassignments

import com.zegreatrob.coupling.action.pairassignmentdocument.DeletePairAssignmentsCommand
import com.zegreatrob.coupling.client.components.Controls
import com.zegreatrob.coupling.model.pairassignmentdocument.PairAssignmentDocument
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.Boolean
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.PairSectionPanel(
  party: PartyDetails,
  players: List<Player>,
  pairAssignments: PairAssignmentDocument? = null,
  allowSave: Boolean,
  setPairs: (PairAssignmentDocument) -> Unit,
  controls: Controls<DeletePairAssignmentsCommand.Dispatcher>,
  key: Key? = null,
) {
    val component = (PairSectionPanel.unsafeCast<FC<PairSectionPanelProps>>())
    component {
         this.party = party
  this.players = players
  pairAssignments?.let { this.pairAssignments = it }
  this.allowSave = allowSave
  this.setPairs = setPairs
  this.controls = controls
  key?.let { this.key = it }
         }
}
