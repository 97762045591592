package com.zegreatrob.coupling.client.components.pairassignments

import csstype.PropertiesBuilder
import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import kotlin.js.Json
import react.ChildrenBuilder
import react.FC
import react.Key
import react.ReactNode

public fun ChildrenBuilder.DraggableThing(
  itemType: String,
  itemId: String,
  dropCallback: (String) -> Unit,
  endCallback: (String, Json?) -> Unit,
  css: (PropertiesBuilder) -> Unit,
  handler: (Boolean) -> ReactNode,
  key: Key? = null,
) {
    val component = (DraggableThing.unsafeCast<FC<DraggableThingProps>>())
    component {
         this.itemType = itemType
  this.itemId = itemId
  this.dropCallback = dropCallback
  this.endCallback = endCallback
  this.css = css
  this.handler = handler
  key?.let { this.key = it }
         }
}
