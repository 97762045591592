package com.zegreatrob.coupling.client.demo

import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun LoadingPageFrameProps.component1(): LoadingAnimationState = state

public operator fun LoadingPageFrameProps.component2(): Key? = key

public fun ElementType<LoadingPageFrameProps>.create(state: LoadingAnimationState, key: Key? = null): ReactNode {
   return create {
         this.state = state
  key?.let { this.key = it }
         }
}
