package com.zegreatrob.coupling.client.components.pairassignments

import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun DroppableThingProps.component1(): String = itemType

public operator fun DroppableThingProps.component2(): (String) -> Unit = dropCallback

public operator fun DroppableThingProps.component3(): (Boolean) -> ReactNode = handler

public operator fun DroppableThingProps.component4(): Key? = key

public fun ElementType<DroppableThingProps>.create(
  itemType: String,
  dropCallback: (String) -> Unit,
  handler: (Boolean) -> ReactNode,
  key: Key? = null,
): ReactNode {
   return create {
         this.itemType = itemType
  this.dropCallback = dropCallback
  this.handler = handler
  key?.let { this.key = it }
         }
}
