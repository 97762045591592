package com.zegreatrob.coupling.client.components.party

import com.zegreatrob.coupling.action.secret.CreateSecretCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.party.PartyId
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.CreateSecretPanel(
  partyId: PartyId,
  dispatcher: DispatchFunc<CreateSecretCommand.Dispatcher>,
  key: Key? = null,
) {
    val component = (CreateSecretPanel.unsafeCast<FC<CreateSecretPanelProps>>())
    component {
         this.partyId = partyId
  this.dispatcher = dispatcher
  key?.let { this.key = it }
         }
}
