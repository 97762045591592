package com.zegreatrob.coupling.client.components.welcome

import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.Welcome(randomProvider: RandomProvider? = null, key: Key? = null) {
    val component = (Welcome.unsafeCast<FC<WelcomeProps>>())
    component {
         randomProvider?.let { this.randomProvider = it }
  key?.let { this.key = it }
         }
}
