package com.zegreatrob.coupling.client.components.`external`.auth0.react

import js.objects.jso
import kotlin.String

public operator fun Auth0LogoutParams.component1(): String = returnTo

public fun Auth0LogoutParams(returnTo: String): Auth0LogoutParams {
    return jso<Auth0LogoutParams> {
         this.returnTo = returnTo
         }
}
