package com.zegreatrob.coupling.client.components.pairassignments

import com.zegreatrob.coupling.action.pairassignmentdocument.DeletePairAssignmentsCommand
import com.zegreatrob.coupling.client.components.Controls
import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.CouplingSocketMessage
import com.zegreatrob.coupling.model.pairassignmentdocument.PairAssignmentDocument
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.Boolean
import kotlin.Unit
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PairAssignmentsProps.component1(): PartyDetails = party

public operator fun PairAssignmentsProps.component2(): Boost? = boost

public operator fun PairAssignmentsProps.component3(): List<Player> = players

public operator fun PairAssignmentsProps.component4(): PairAssignmentDocument? = pairs

public operator fun PairAssignmentsProps.component5(): (PairAssignmentDocument) -> Unit = setPairs

public operator fun PairAssignmentsProps.component6(): Controls<DeletePairAssignmentsCommand.Dispatcher> = controls

public operator fun PairAssignmentsProps.component7(): CouplingSocketMessage = message

public operator fun PairAssignmentsProps.component8(): Boolean = allowSave

public operator fun PairAssignmentsProps.component9(): Key? = key

public fun ElementType<PairAssignmentsProps>.create(
  party: PartyDetails,
  boost: Boost? = null,
  players: List<Player>,
  pairs: PairAssignmentDocument? = null,
  setPairs: (PairAssignmentDocument) -> Unit,
  controls: Controls<DeletePairAssignmentsCommand.Dispatcher>,
  message: CouplingSocketMessage,
  allowSave: Boolean,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  boost?.let { this.boost = it }
  this.players = players
  pairs?.let { this.pairs = it }
  this.setPairs = setPairs
  this.controls = controls
  this.message = message
  this.allowSave = allowSave
  key?.let { this.key = it }
         }
}
