package com.zegreatrob.coupling.client.components

import kotlin.Boolean
import kotlin.Unit
import react.ChildrenBuilder
import react.ElementType
import react.Fragment
import react.Key
import react.MutableRefObject
import react.ReactNode
import react.create
import react.popper.PopperInstance
import web.html.HTMLElement

public operator fun CouplingPopUpProps.component1(): Boolean = hide

public operator fun CouplingPopUpProps.component2(): MutableRefObject<HTMLElement> = popperRef

public operator fun CouplingPopUpProps.component3(): MutableRefObject<HTMLElement> = arrowRef

public operator fun CouplingPopUpProps.component4(): PopperInstance = popperInstance

public operator fun CouplingPopUpProps.component5(): ReactNode? = children

public operator fun CouplingPopUpProps.component6(): Key? = key

public fun ElementType<CouplingPopUpProps>.create(
  hide: Boolean,
  popperRef: MutableRefObject<HTMLElement>,
  arrowRef: MutableRefObject<HTMLElement>,
  popperInstance: PopperInstance,
  key: Key? = null,
  children: ChildrenBuilder.() -> Unit = {},
): ReactNode {
   return create {
         this.hide = hide
  this.popperRef = popperRef
  this.arrowRef = arrowRef
  this.popperInstance = popperInstance
  key?.let { this.key = it }
  Fragment { children() }

         }
}
