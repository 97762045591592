package com.zegreatrob.coupling.client.demo

import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.DemoPageFrame(state: DemoAnimationState, key: Key? = null) {
    val component = (DemoPageFrame.unsafeCast<FC<DemoPageFrameProps>>())
    component {
         this.state = state
  key?.let { this.key = it }
         }
}
