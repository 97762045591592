package com.zegreatrob.coupling.client.components.pin

import com.zegreatrob.coupling.model.pin.Pin
import kotlin.String
import kotlin.Unit
import kotlin.js.Json
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.DraggablePinButton(
  pin: Pin,
  scale: PinButtonScale,
  endCallback: (String, Json?) -> Unit,
  key: Key? = null,
) {
    val component = (DraggablePinButton.unsafeCast<FC<DraggablePinButtonProps>>())
    component {
         this.pin = pin
  this.scale = scale
  this.endCallback = endCallback
  key?.let { this.key = it }
         }
}
