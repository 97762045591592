package com.zegreatrob.coupling.client.components.pin

import com.zegreatrob.coupling.model.pin.Pin
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import kotlin.js.Json
import react.ElementType
import react.Key
import react.ReactNode
import react.create
import web.cssom.ClassName

public operator fun PinSectionProps.component1(): List<Pin> = pinList

public operator fun PinSectionProps.component2(): PinButtonScale? = scale

public operator fun PinSectionProps.component3(): ClassName? = className

public operator fun PinSectionProps.component4(): ((String, Json?) -> Unit)? = endCallback

public operator fun PinSectionProps.component5(): Key? = key

public fun ElementType<PinSectionProps>.create(
  pinList: List<Pin>,
  scale: PinButtonScale? = null,
  className: ClassName? = null,
  endCallback: ((String, Json?) -> Unit)? = null,
  key: Key? = null,
): ReactNode {
   return create {
         this.pinList = pinList
  scale?.let { this.scale = it }
  className?.let { this.className = it }
  endCallback?.let { this.endCallback = it }
  key?.let { this.key = it }
         }
}
