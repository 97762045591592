package com.zegreatrob.coupling.client.routing

import com.zegreatrob.coupling.client.ClientConfig
import kotlin.Boolean
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun CouplingRouterProps.component1(): Boolean = animationsDisabled

public operator fun CouplingRouterProps.component2(): Boolean = thirdPartyAvatarsDisabled

public operator fun CouplingRouterProps.component3(): ClientConfig = config

public operator fun CouplingRouterProps.component4(): Key? = key

public fun ElementType<CouplingRouterProps>.create(
  animationsDisabled: Boolean,
  thirdPartyAvatarsDisabled: Boolean,
  config: ClientConfig,
  key: Key? = null,
): ReactNode {
   return create {
         this.animationsDisabled = animationsDisabled
  this.thirdPartyAvatarsDisabled = thirdPartyAvatarsDisabled
  this.config = config
  key?.let { this.key = it }
         }
}
