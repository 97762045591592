package com.zegreatrob.coupling.client.components.player

import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun TinyPlayerListProps.component1(): PartyDetails = party

public operator fun TinyPlayerListProps.component2(): List<Player> = players

public operator fun TinyPlayerListProps.component3(): Key? = key

public fun ElementType<TinyPlayerListProps>.create(
  party: PartyDetails,
  players: List<Player>,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  this.players = players
  key?.let { this.key = it }
         }
}
