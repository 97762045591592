package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.model.Message
import com.zegreatrob.coupling.model.party.PartyId
import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun CouplingWebsocketProps.component1(): PartyId = partyId

public operator fun CouplingWebsocketProps.component2(): (Message) -> Unit = onMessage

public operator fun CouplingWebsocketProps.component3(): (((Message) -> Unit)?) -> ReactNode = buildChild

public operator fun CouplingWebsocketProps.component4(): String = token

public operator fun CouplingWebsocketProps.component5(): Boolean? = useSsl

public operator fun CouplingWebsocketProps.component6(): Key? = key

public fun ElementType<CouplingWebsocketProps>.create(
  partyId: PartyId,
  onMessage: (Message) -> Unit,
  buildChild: (((Message) -> Unit)?) -> ReactNode,
  token: String,
  useSsl: Boolean? = null,
  key: Key? = null,
): ReactNode {
   return create {
         this.partyId = partyId
  this.onMessage = onMessage
  this.buildChild = buildChild
  this.token = token
  useSsl?.let { this.useSsl = it }
  key?.let { this.key = it }
         }
}
