package com.zegreatrob.coupling.client.components.stats

import kotlin.Int
import kotlin.time.Duration
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.TeamStatistics(
  spinsUntilFullRotation: Int,
  activePlayerCount: Int,
  medianSpinDuration: Duration? = null,
  key: Key? = null,
) {
    val component = (TeamStatistics.unsafeCast<FC<TeamStatisticsProps>>())
    component {
         this.spinsUntilFullRotation = spinsUntilFullRotation
  this.activePlayerCount = activePlayerCount
  medianSpinDuration?.let { this.medianSpinDuration = it }
  key?.let { this.key = it }
         }
}
