package com.zegreatrob.coupling.client.components.gravatar

import js.objects.jso
import kotlin.Int
import kotlin.String

public operator fun GravatarOptions.component1(): String = default

public operator fun GravatarOptions.component2(): Int = size

public fun GravatarOptions(default: String, size: Int): GravatarOptions {
    return jso<GravatarOptions> {
         this.default = default
  this.size = size
         }
}
