package com.zegreatrob.coupling.client.integration

import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.party.PartyIntegration
import kotlin.String
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun IntegrationContentProps.component1(): PartyDetails = party

public operator fun IntegrationContentProps.component2(): PartyIntegration? = integration

public operator fun IntegrationContentProps.component3(): String = addToSlackUrl

public operator fun IntegrationContentProps.component4(): Key? = key

public fun ElementType<IntegrationContentProps>.create(
  party: PartyDetails,
  integration: PartyIntegration? = null,
  addToSlackUrl: String,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  integration?.let { this.integration = it }
  this.addToSlackUrl = addToSlackUrl
  key?.let { this.key = it }
         }
}
