package com.zegreatrob.coupling.client.components.party

import com.zegreatrob.coupling.action.secret.DeleteSecretCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.party.PartyId
import com.zegreatrob.coupling.model.party.Secret
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.DeleteSecretButton(
  partyId: PartyId,
  secret: Secret,
  dispatcher: DispatchFunc<DeleteSecretCommand.Dispatcher>,
  onSuccess: () -> Unit,
  key: Key? = null,
) {
    val component = (DeleteSecretButton.unsafeCast<FC<DeleteSecretButtonProps>>())
    component {
         this.partyId = partyId
  this.secret = secret
  this.dispatcher = dispatcher
  this.onSuccess = onSuccess
  key?.let { this.key = it }
         }
}
