package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.model.CouplingSocketMessage
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.ServerMessage(message: CouplingSocketMessage, key: Key? = null) {
    val component = (ServerMessage.unsafeCast<FC<ServerMessageProps>>())
    component {
         this.message = message
  key?.let { this.key = it }
         }
}
