package com.zegreatrob.coupling.client.components.party

import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PartyBrowserProps.component1(): PartyDetails = party

public operator fun PartyBrowserProps.component2(): Boost? = boost

public operator fun PartyBrowserProps.component3(): Key? = key

public fun ElementType<PartyBrowserProps>.create(
  party: PartyDetails,
  boost: Boost? = null,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  boost?.let { this.boost = it }
  key?.let { this.key = it }
         }
}
