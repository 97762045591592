package com.zegreatrob.coupling.client.components.player

import com.zegreatrob.coupling.model.party.PartyId
import com.zegreatrob.coupling.model.player.Player
import csstype.PropertiesBuilder
import kotlin.String
import kotlin.Unit
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create
import web.cssom.ClassName

public operator fun PlayerRosterProps.component1(): String? = label

public operator fun PlayerRosterProps.component2(): List<Player> = players

public operator fun PlayerRosterProps.component3(): PartyId = partyId

public operator fun PlayerRosterProps.component4(): ClassName? = className

public operator fun PlayerRosterProps.component5(): ((PropertiesBuilder) -> Unit)? = cssOverrides

public operator fun PlayerRosterProps.component6(): Key? = key

public fun ElementType<PlayerRosterProps>.create(
  label: String? = null,
  players: List<Player>,
  partyId: PartyId,
  className: ClassName? = null,
  cssOverrides: ((PropertiesBuilder) -> Unit)? = null,
  key: Key? = null,
): ReactNode {
   return create {
         label?.let { this.label = it }
  this.players = players
  this.partyId = partyId
  className?.let { this.className = it }
  cssOverrides?.let { this.cssOverrides = it }
  key?.let { this.key = it }
         }
}
