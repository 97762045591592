package com.zegreatrob.coupling.client.components.player

import com.zegreatrob.coupling.model.player.Player
import kotlin.Boolean
import kotlin.Int
import kotlin.Unit
import react.ElementType
import react.Key
import react.ReactNode
import react.create
import web.cssom.Angle
import web.cssom.ClassName

public operator fun PlayerCardProps.component1(): Player = player

public operator fun PlayerCardProps.component2(): ClassName? = className

public operator fun PlayerCardProps.component3(): (() -> Unit)? = onClick

public operator fun PlayerCardProps.component4(): Int? = size

public operator fun PlayerCardProps.component5(): Boolean? = deselected

public operator fun PlayerCardProps.component6(): Angle? = tilt

public operator fun PlayerCardProps.component7(): Key? = key

public fun ElementType<PlayerCardProps>.create(
  player: Player,
  className: ClassName? = null,
  onClick: (() -> Unit)? = null,
  size: Int? = null,
  deselected: Boolean? = null,
  tilt: Angle? = null,
  key: Key? = null,
): ReactNode {
   return create {
         this.player = player
  className?.let { this.className = it }
  onClick?.let { this.onClick = it }
  size?.let { this.size = it }
  deselected?.let { this.deselected = it }
  tilt?.let { this.tilt = it }
  key?.let { this.key = it }
         }
}
