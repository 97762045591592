package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.model.CouplingSocketMessage
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun ServerMessageProps.component1(): CouplingSocketMessage = message

public operator fun ServerMessageProps.component2(): Key? = key

public fun ElementType<ServerMessageProps>.create(message: CouplingSocketMessage, key: Key? = null): ReactNode {
   return create {
         this.message = message
  key?.let { this.key = it }
         }
}
