package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.model.Boost
import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Fragment
import react.Key

public fun ChildrenBuilder.ConfigHeader(
  party: PartyDetails,
  boost: Boost? = null,
  key: Key? = null,
  children: ChildrenBuilder.() -> Unit = {},
) {
    val component = (ConfigHeader.unsafeCast<FC<ConfigHeaderProps>>())
    component {
         this.party = party
  boost?.let { this.boost = it }
  key?.let { this.key = it }
  Fragment { children() }

         }
}
