package com.zegreatrob.coupling.client.player.retired

import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun RetiredPlayersProps.component1(): PartyDetails = party

public operator fun RetiredPlayersProps.component2(): List<Player> = retiredPlayers

public operator fun RetiredPlayersProps.component3(): Key? = key

public fun ElementType<RetiredPlayersProps>.create(
  party: PartyDetails,
  retiredPlayers: List<Player>,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  this.retiredPlayers = retiredPlayers
  key?.let { this.key = it }
         }
}
