package com.zegreatrob.coupling.client.demo

import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun DemoPageFrameProps.component1(): DemoAnimationState = state

public operator fun DemoPageFrameProps.component2(): Key? = key

public fun ElementType<DemoPageFrameProps>.create(state: DemoAnimationState, key: Key? = null): ReactNode {
   return create {
         this.state = state
  key?.let { this.key = it }
         }
}
