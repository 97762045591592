package com.zegreatrob.coupling.client.components.pairassignments

import com.zegreatrob.coupling.action.pairassignmentdocument.DeletePairAssignmentsCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.model.pairassignmentdocument.PairAssignmentDocument
import com.zegreatrob.coupling.model.party.PartyDetails
import kotlin.Boolean
import kotlin.Unit
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun CurrentPairAssignmentsPanelProps.component1(): PartyDetails = party

public operator fun CurrentPairAssignmentsPanelProps.component2(): PairAssignmentDocument = pairAssignments

public operator fun CurrentPairAssignmentsPanelProps.component3(): (PairAssignmentDocument) -> Unit = setPairAssignments

public operator fun CurrentPairAssignmentsPanelProps.component4(): Boolean = allowSave

public operator fun CurrentPairAssignmentsPanelProps.component5(): DispatchFunc<DeletePairAssignmentsCommand.Dispatcher> = dispatchFunc

public operator fun CurrentPairAssignmentsPanelProps.component6(): Key? = key

public fun ElementType<CurrentPairAssignmentsPanelProps>.create(
  party: PartyDetails,
  pairAssignments: PairAssignmentDocument,
  setPairAssignments: (PairAssignmentDocument) -> Unit,
  allowSave: Boolean,
  dispatchFunc: DispatchFunc<DeletePairAssignmentsCommand.Dispatcher>,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  this.pairAssignments = pairAssignments
  this.setPairAssignments = setPairAssignments
  this.allowSave = allowSave
  this.dispatchFunc = dispatchFunc
  key?.let { this.key = it }
         }
}
