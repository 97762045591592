package com.zegreatrob.coupling.client.components.pairassignments

import kotlin.Boolean
import kotlin.String
import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Key
import react.ReactNode

public fun ChildrenBuilder.DroppableThing(
  itemType: String,
  dropCallback: (String) -> Unit,
  handler: (Boolean) -> ReactNode,
  key: Key? = null,
) {
    val component = (DroppableThing.unsafeCast<FC<DroppableThingProps>>())
    component {
         this.itemType = itemType
  this.dropCallback = dropCallback
  this.handler = handler
  key?.let { this.key = it }
         }
}
