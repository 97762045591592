package com.zegreatrob.coupling.client.routing

import com.zegreatrob.coupling.client.ClientConfig
import kotlin.String
import react.ElementType
import react.FC
import react.Key
import react.ReactNode
import react.create

public operator fun CouplingRouteProps.component1(): FC<PageProps> = rComponent

public operator fun CouplingRouteProps.component2(): String = title

public operator fun CouplingRouteProps.component3(): ClientConfig = config

public operator fun CouplingRouteProps.component4(): Key? = key

public fun ElementType<CouplingRouteProps>.create(
  rComponent: FC<PageProps>,
  title: String,
  config: ClientConfig,
  key: Key? = null,
): ReactNode {
   return create {
         this.rComponent = rComponent
  this.title = title
  this.config = config
  key?.let { this.key = it }
         }
}
