package com.zegreatrob.coupling.client.components.contribution

import com.zegreatrob.coupling.action.player.SavePlayerCommand
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.coupling.json.GqlContributionWindow
import com.zegreatrob.coupling.model.Contribution
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.ContributionListContent(
  party: PartyDetails,
  contributions: List<Contribution>,
  contributors: List<Player>,
  window: GqlContributionWindow,
  setWindow: (GqlContributionWindow) -> Unit,
  players: List<Player>,
  dispatchFunc: DispatchFunc<SavePlayerCommand.Dispatcher>,
  key: Key? = null,
) {
    val component = (ContributionListContent.unsafeCast<FC<ContributionListContentProps>>())
    component {
         this.party = party
  this.contributions = contributions
  this.contributors = contributors
  this.window = window
  this.setWindow = setWindow
  this.players = players
  this.dispatchFunc = dispatchFunc
  key?.let { this.key = it }
         }
}
