package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.action.player.SavePlayerCommand
import com.zegreatrob.coupling.model.party.PartyId
import com.zegreatrob.coupling.model.player.Player
import kotlin.Unit
import kotlin.collections.List
import popper.core.ReferenceElement
import react.ChildrenBuilder
import react.FC
import react.Key
import react.ReactNode

public fun ChildrenBuilder.ContributionPopUpMenu(
  partyId: PartyId,
  players: List<Player>,
  dispatchFunc: DispatchFunc<SavePlayerCommand.Dispatcher>,
  child: ((ReferenceElement, Player) -> Unit) -> ReactNode,
  key: Key? = null,
) {
    val component = (ContributionPopUpMenu.unsafeCast<FC<ContributionPopUpMenuProps>>())
    component {
         this.partyId = partyId
  this.players = players
  this.dispatchFunc = dispatchFunc
  this.child = child
  key?.let { this.key = it }
         }
}
