package com.zegreatrob.coupling.client.components.pairassignments

import com.zegreatrob.coupling.model.party.PartyDetails
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.ControlPanel(party: PartyDetails, key: Key? = null) {
    val component = (ControlPanel.unsafeCast<FC<ControlPanelProps>>())
    component {
         this.party = party
  key?.let { this.key = it }
         }
}
