package com.zegreatrob.coupling.client.components.pairassignments

import com.zegreatrob.coupling.action.pairassignmentdocument.DeletePairAssignmentsCommand
import com.zegreatrob.coupling.client.components.Controls
import com.zegreatrob.coupling.model.pairassignmentdocument.PairAssignmentDocument
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.Boolean
import kotlin.Unit
import kotlin.collections.List
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PairSectionProps.component1(): PartyDetails = party

public operator fun PairSectionProps.component2(): List<Player> = players

public operator fun PairSectionProps.component3(): PairAssignmentDocument? = pairAssignments

public operator fun PairSectionProps.component4(): Boolean = allowSave

public operator fun PairSectionProps.component5(): (PairAssignmentDocument) -> Unit = setPairs

public operator fun PairSectionProps.component6(): Controls<DeletePairAssignmentsCommand.Dispatcher> = controls

public operator fun PairSectionProps.component7(): Key? = key

public fun ElementType<PairSectionProps>.create(
  party: PartyDetails,
  players: List<Player>,
  pairAssignments: PairAssignmentDocument? = null,
  allowSave: Boolean,
  setPairs: (PairAssignmentDocument) -> Unit,
  controls: Controls<DeletePairAssignmentsCommand.Dispatcher>,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  this.players = players
  pairAssignments?.let { this.pairAssignments = it }
  this.allowSave = allowSave
  this.setPairs = setPairs
  this.controls = controls
  key?.let { this.key = it }
         }
}
