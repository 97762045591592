package com.zegreatrob.coupling.client.components.stats

import kotlin.Unit
import react.ChildrenBuilder
import react.FC
import react.Fragment
import react.Key
import react.ReactNode
import web.cssom.BackgroundColor

public fun ChildrenBuilder.CouplingLabelWrapper(
  label: ReactNode? = null,
  backgroundColor: BackgroundColor? = null,
  key: Key? = null,
  children: ChildrenBuilder.() -> Unit = {},
) {
    val component = (CouplingLabelWrapper.unsafeCast<FC<CouplingLabelWrapperProps>>())
    component {
         label?.let { this.label = it }
  backgroundColor?.let { this.backgroundColor = it }
  key?.let { this.key = it }
  Fragment { children() }

         }
}
