package com.zegreatrob.coupling.client.routing

import com.zegreatrob.coupling.client.ClientDispatcher
import com.zegreatrob.coupling.client.components.DispatchFunc
import com.zegreatrob.react.dataloader.ReloadFunc
import com.zegreatrob.testmints.action.async.SuspendAction
import react.ChildrenBuilder
import react.FC
import react.Key
import react.ReactNode

public fun <R> ChildrenBuilder.CouplingQuery(
  query: SuspendAction<ClientDispatcher, R?>,
  toNode: (
    ReloadFunc,
    DispatchFunc<ClientDispatcher>,
    R,
  ) -> ReactNode?,
  commander: Commander,
  key: Key? = null,
) {
    val component = (CouplingQuery.unsafeCast<FC<CouplingQueryProps<R>>>())
    component {
         this.query = query
  this.toNode = toNode
  this.commander = commander
  key?.let { this.key = it }
         }
}
