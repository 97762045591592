package com.zegreatrob.coupling.client.components.`external`.reactwebsocket

import js.objects.jso
import kotlin.Array
import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.Unit
import kotlin.js.Json
import org.w3c.dom.CloseEvent
import org.w3c.dom.ErrorEvent
import org.w3c.dom.EventSourceInit
import org.w3c.dom.MessageEvent
import org.w3c.dom.events.Event

public operator fun UseWebSocketOptions.component1(): Boolean? = fromSocketIO

public operator fun UseWebSocketOptions.component2(): Json? = queryParams

public operator fun UseWebSocketOptions.component3(): Array<String>? = protocols

public operator fun UseWebSocketOptions.component4(): Boolean? = share

public operator fun UseWebSocketOptions.component5(): Int? = reconnectIntervar

public operator fun UseWebSocketOptions.component6(): Int? = reconnectAttempts

public operator fun UseWebSocketOptions.component7(): Boolean? = retryOnError

public operator fun UseWebSocketOptions.component8(): ((Event) -> Unit)? = onOpen

public operator fun UseWebSocketOptions.component9(): ((CloseEvent) -> Unit)? = onClose

public operator fun UseWebSocketOptions.component10(): ((MessageEvent) -> Unit)? = onMessage

public operator fun UseWebSocketOptions.component11(): ((ErrorEvent) -> Unit)? = onError

public operator fun UseWebSocketOptions.component12(): ((Int) -> Unit)? = onReconnectStop

public operator fun UseWebSocketOptions.component13(): ((CloseEvent) -> Boolean)? = shouldReconnect

public operator fun UseWebSocketOptions.component14(): ((MessageEvent) -> Boolean)? = filter

public operator fun UseWebSocketOptions.component15(): EventSourceInit? = eventSourceOptions

public fun UseWebSocketOptions(
  fromSocketIO: Boolean? = null,
  queryParams: Json? = null,
  protocols: Array<String>? = null,
  share: Boolean? = null,
  reconnectIntervar: Int? = null,
  reconnectAttempts: Int? = null,
  retryOnError: Boolean? = null,
  onOpen: ((Event) -> Unit)? = null,
  onClose: ((CloseEvent) -> Unit)? = null,
  onMessage: ((MessageEvent) -> Unit)? = null,
  onError: ((ErrorEvent) -> Unit)? = null,
  onReconnectStop: ((Int) -> Unit)? = null,
  shouldReconnect: ((CloseEvent) -> Boolean)? = null,
  filter: ((MessageEvent) -> Boolean)? = null,
  eventSourceOptions: EventSourceInit? = null,
): UseWebSocketOptions {
    return jso<UseWebSocketOptions> {
         fromSocketIO?.let { this.fromSocketIO = it }
  queryParams?.let { this.queryParams = it }
  protocols?.let { this.protocols = it }
  share?.let { this.share = it }
  reconnectIntervar?.let { this.reconnectIntervar = it }
  reconnectAttempts?.let { this.reconnectAttempts = it }
  retryOnError?.let { this.retryOnError = it }
  onOpen?.let { this.onOpen = it }
  onClose?.let { this.onClose = it }
  onMessage?.let { this.onMessage = it }
  onError?.let { this.onError = it }
  onReconnectStop?.let { this.onReconnectStop = it }
  shouldReconnect?.let { this.shouldReconnect = it }
  filter?.let { this.filter = it }
  eventSourceOptions?.let { this.eventSourceOptions = it }
         }
}
