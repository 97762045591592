package com.zegreatrob.coupling.client.components.pairassignments

import com.zegreatrob.coupling.model.pairassignmentdocument.PairAssignmentDocument
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PairAssignmentsHeaderProps.component1(): PairAssignmentDocument = pairAssignments

public operator fun PairAssignmentsHeaderProps.component2(): Key? = key

public fun ElementType<PairAssignmentsHeaderProps>.create(pairAssignments: PairAssignmentDocument, key: Key? = null): ReactNode {
   return create {
         this.pairAssignments = pairAssignments
  key?.let { this.key = it }
         }
}
