package com.zegreatrob.coupling.client.components.contribution

import com.zegreatrob.coupling.client.components.PartyButtonProps
import com.zegreatrob.coupling.model.party.PartyId
import react.ChildrenBuilder
import react.FC
import react.Key

public fun ChildrenBuilder.ContributionListButton(partyId: PartyId, key: Key? = null) {
    val component = (ContributionListButton.unsafeCast<FC<PartyButtonProps>>())
    component {
         this.partyId = partyId
  key?.let { this.key = it }
         }
}
