package com.zegreatrob.coupling.client.components.contribution

import com.zegreatrob.coupling.model.Contribution
import com.zegreatrob.coupling.model.player.Player
import kotlin.Unit
import kotlin.collections.List
import react.ChildrenBuilder
import react.FC
import react.Key
import web.html.HTMLElement

public fun ChildrenBuilder.ContributionCard(
  contribution: Contribution,
  contributors: List<Player>,
  onPlayerClick: ((Player, HTMLElement) -> Unit)? = null,
  key: Key? = null,
) {
    val component = (ContributionCard.unsafeCast<FC<ContributionCardProps>>())
    component {
         this.contribution = contribution
  this.contributors = contributors
  onPlayerClick?.let { this.onPlayerClick = it }
  key?.let { this.key = it }
         }
}
