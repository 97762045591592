package com.zegreatrob.coupling.client.components

import com.zegreatrob.coupling.model.party.PartyId
import react.ElementType
import react.Key
import react.ReactNode
import react.create

public operator fun PartyButtonProps.component1(): PartyId = partyId

public operator fun PartyButtonProps.component2(): Key? = key

public fun ElementType<PartyButtonProps>.create(partyId: PartyId, key: Key? = null): ReactNode {
   return create {
         this.partyId = partyId
  key?.let { this.key = it }
         }
}
